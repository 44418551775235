<template>
  <v-dialog v-model="dialog" persistent max-width="530">
    <v-card class="app-no-project-modal">
      <v-card-title>
        <span class="app-no-project-modal__title text--danger">
          Vous avez aucun projet assigné a votre compte. <br />Veuillez
          communiquer le souci au support.</span
        >
      </v-card-title>
      <v-card-text class="text-center mt-3">
        <v-btn color="primary" depressed @click="logout"> Déconnexion </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppNoProjectModal",
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
  },
  methods: {
    logout() {
      this.$store.commit("SET_LOADER_STATUS", true);
      this.$store.commit("authModule/LOGOUT");
      this.$Tawk.$endChat();
    },
  },
};
</script>

<style src="./AppNoProjectModal.scss" lang="scss" scoped></style>